@import "./foundation/settings";
@import "~foundation-sites/scss/foundation";
@include foundation-everything;
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ewert&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Reenie+Beanie&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Reenie+Beanie&family=Sunflower:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Quicksand&display=swap");

// place global css here
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
}

.card {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 380px;
  justify-content: space-around;
  background-color: rgba(208, 210, 211, 0.041);
  transition: transform 0.09s;
  border-radius: 2%;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

  // box-shadow: 1px 1px 0px 0px #cecbcb;
}

.card-logo {
  filter: grayscale(1);
}

.card:hover {
  transform: scale(
    1.01
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.serial {
  font-display: swap;
  font-family: "Press Start 2P", cursive;
  // font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 18pt;
  max-width: 280px;
  grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  display: flex;
}

.date {
  font-display: swap;
  color: #020202;
  font-family: "Quicksand", sans-serif;
  // font-family: "Open Sans", Arial, sans-serif;
  font-size: 1.5rem;
  max-width: 280px;
  grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.address {
  color: #020202;
  font-display: swap;
  font-family: "Quicksand", sans-serif;
  // font-family: "Press Start 2P", cursive;
  // font-family: "Press Start 2P", cursive;
  // font-family: "Open Sans", Arial, sans-serif;
  font-size: 1.2rem;
  max-width: 280px;
  grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.status {
  color: #222121;

  margin-bottom: 4pt;
  margin-top: 1rem;

  font-family: "Press Start 2P", cursive;
  font-display: swap;

  font-weight: bolder;
  font-size: 1.4rem;
  max-width: 280px;
  grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.deposit {
  color: #020202;
  font-family: "Quicksand", sans-serif;
  font-display: swap;

  font-size: 1.2rem;
  max-width: 280px;
  grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-bottom: 1rem;
}

.style {
  font-family: "Quicksand", sans-serif;
  font-display: swap;
  color: #020202;

  font-size: 1.2rem;
  max-width: 280px;
  grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  display: flex;
}

.list-item {
  justify-content: center;

  display: grid;
  padding: 0 10vw;

  grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));

  gap: 1px;
}

.icon-image img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 1.5rem;
  height: 6vh;
}

.main-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  width: 100vw;
  padding: 0 10vw;
  color: #000;
  box-shadow: 1.8px 1.8px 1.8px 1.8px rgba(212, 214, 212, 0.5);
  z-index: 1;
}

.thumb {
  border-radius: 3px;
  width: auto;
  height: 10vh;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.logo {
  color: #020202;
  font-family: "Ewert", cursive;
  font-display: swap;
  font-size: 2rem;
  max-width: 400px;

  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-bottom: 1rem;
  object-fit: cover;
}

.not-container {
  // background: url("https://i.postimg.cc/wTjbqjsv/20945153-Convert-Image.jpg") no-repeat center
  //   center;

  text-align: center;
  width: 100%;
  height: 44vh;
  margin-bottom: -1rem;
  background-size: contain;
  display: flex;
}

.not-container2 {
  width: 70vh;
  margin: auto;
  height: 40vh;
  filter: grayscale(1);
  margin-bottom: 30px;
}
.message-container {
  display: grid;
  place-items: center;
}

.Signup-alert {
  max-width: 900px;
  border-radius: 0.5px 25px 0.5px;
}

// .alert-success {
//   color: #155724;
//   background-color: #d4edda;
//   border-color: #c3e6cb;
// }

.heading-primary {
  text-align: center;
  animation-name: moveInLeft;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

.heading-primary-main {
  animation: moveInRight 0.5s ease-out;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translate(-100px);
  }
  80% {
    opacity: 0;
    transform: translate(10px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translate(100px);
  }
  80% {
    opacity: 0;
    transform: translate(-10px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.octopus {
  height: 30vh;
  margin-bottom: 0%;
  margin-top: 0px;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-display: swap;
  font-size: 1.8em;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: -1.4rem;
  margin-bottom: 4rem;
  text-align: center;
}

@media (max-width: 26.25em) {
  h3 {
    font-size: 1.2em;
  }
}

h1 {
  font-family: "Reenie Beanie", cursive;
  font-display: swap;
  font-size: 6em;

  margin: 0 auto;
  margin-bottom: -1rem;
  margin-top: 0.2em;
  text-align: center;
}

@media (max-width: 26.25em) {
  h1 {
    font-size: 4em;
  }
}

.not-map {
  width: 100%;
  height: 60vh;
  padding: 10px;
  margin-bottom: 2rem;
  position: relative;
}

.map {
  width: 100%;
  // height: 60vh;
  padding: 10px;
  margin-bottom: 1rem;
  position: relative;
}

.webgl {
  position: absolute;
  top: 0;
  left: 10px;
  outline: none;
  mix-blend-mode: exclusion;
}

.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  display: grid;
  place-items: center;
  left: 50%; /* Move it to the middle */
  transform: translateX(-50%); /* Center it horizontally */
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

@keyframes scrollTop {
  from {
    transform: translateY(2px);
  }
  to {
    transform: translateY(-1px);
  }
}

.to-top {
  width: auto;
  height: 10vh;

  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.navbar-light .navbar-brand {
  margin-left: 0.5rem;
}

.favorite-button {
  width: auto;
  height: 4vh;
  margin-bottom: 10pt;
  outline: none;
}

.delete-button-container {
  display: grid;
  place-items: center;
}

.favorite-delete-button {
  width: auto;
  max-width: 200px;
  margin-bottom: 15px;

  position: relative;
  display: inline-block;
  padding: 5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid grey;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.favorite-delete-button:hover {
  background-color: #f74d4db4;
  outline: 0;
}

button:focus {
  outline: none;
}

.button-container {
  width: 100%;
  height: 10vh;
  color: rgba(253, 251, 251, 0.849) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  font-family: "Ubuntu Mono", monospace;
  font-display: swap;
  border-radius: 2rem;
  font-weight: 600;
  margin-right: 10pt;
  list-style: none;
}

.sign-out-button {
  list-style: none;
  max-width: 200px;
  width: auto;
  margin-left: 20px;
}

.about-us-text {
  font-display: swap;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  max-width: 1000px;
  margin: 0 auto;
  color: grey;
  text-align: left;
  margin-bottom: 10pt;
  margin-top: 10pt;
}

.about-us-title {
  font-display: swap;
  font-family: "Source Code Pro", monospace;
  font-size: 4em;
  color: rgb(70, 69, 69);
  margin: 0 auto;

  text-align: center;
}

.sign-in {
  margin-right: 10px;
}

.navbar-brand-text {
  margin-right: 0.1rem;
  margin-top: 5px;
  text-align: center;
  display: flex;
  font-size: 1.4rem;
  font-weight: 300;
  color: #312f2f;
}

.d-inline-block {
  margin-left: 1rem;
  border-radius: 49%;
  box-shadow: #383434;
  width: 100;
  height: 4.2vh;
}

.logo-nav-bar {
  font-family: "Reenie Beanie", cursive;
  font-size: 2em;
  margin-left: 3rem;
  margin-right: 7rem;
}

.nav-home {
  margin: auto;
}

@media screen and (max-width: 480px) {
  .nav-home {
    place-items: left;
    margin-left: 3rem;
  }
}

// @media screen and (min-width: 380px) {
//   .nav-home {
//     margin-left: 3rem;
//   }
// }

.navbar-collapse {
  margin-left: -4rem;
}

.nav-link {
  margin-left: 2.5rem;
}

// .nav-home {
//   text-align: left;
// }

// Loader spinner

.loader,
.loader:before,
.loader:after {
  background: #333232;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #333232;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.page-item.active .page-link {
  z-index: 3;
  // color: #fff !important;
  background-color: black !important;
  border-color: black !important;
}

.site-footer {
  max-width: 1255px !important;
  padding-top: 4rem;
}

.left-right {
  font-size: 24px;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding-bottom: 4rem;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: start;
  padding: 2rem;
}

@media only screen and (max-width: 580px) {
  .left-right {
    padding-left: 2.4rem;
    padding-right: 1.4rem;
    flex-direction: column;
  }
}

@media only screen and (max-width: 300px) {
  .left-right {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    display: grid;
  }
}

.left {
  --c-page-ink: var(--ink);
  --c-page-ink-fallback: var(--ink-fallback);
  --c-page-paper: var(--paper);
  --c-page-highlight-1: var(--highlight-1);
  --c-page-highlight-2: var(--highlight-2);
  --c-page-highlight-3: var(--highlight-3);
  --c-page-tag-color: var(--tag-color);
  --wp--preset--font-size--normal: 16px;
  --wp--preset--font-size--huge: 42px;
  --ON: initial;
  --OFF: ;
  --screen-size-xs: 374px;
  --screen-size-sm: 768px;
  --screen-size-md: 991px;
  --screen-size-md-lg: 1160px;
  --screen-size-lg: 1367px;
  --screen-size-xl: 1440px;
  --screen-size-desktop: 1512px;
  --td-container-width-default: 1200px;
  --td-container-width-small: 800px;
  --td-container-width-medium: 1300px;
  --td-container-width-large: 1400px;
  --c-black: #000000;
  --c-traina-purple-400: #8875e2;
  --c-traina-purple-300: #a897f8;
  --c-traina-pink-400: #de706f;
  --c-traina-pink-300: #f98989;
  --c-traina-green-400: #5d9e70;
  --c-traina-green-300: #82c596;
  --c-traina-gray-900: #151515;
  --c-traina-gray-800: #232323;
  --c-traina-gray-700: #454545;
  --c-traina-gray-600: #636363;
  --c-traina-gray-500: #727272;
  --c-traina-gray-400: #8e8e8e;
  --c-traina-gray-300: #d0d0d0;
  --c-traina-gray-200: #e8e8e8;
  --c-traina-gray-110: #f5f5f5;
  --c-traina-gray-100: #fafafa;
  --c-white: #ffffff;
  --c-error-red: #852323;
  --c-primary: var(--ink);
  --c-secondary: var(--paper);
  --ff-sans-serif: "Questrial", sans-serif;
  --ff-serif: "Questrial", sans-serif;
  /* mix-blend-mode: difference; */
  --fw-light: 300;
  --fw-regular: 400;
  --fz-body-details-min: 18;
  --fz-body-details-from: 18;
  --fz-body-details-max: 28;
  --fz-body-details-lh-min: 27;
  --fz-body-details-lh-from: 27;
  --fz-body-details-lh-max: 36;
  --fz-body-lg-min: 30;
  --fz-body-lg-to: 36;
  --fz-body-lg-max: 43;
  --fz-body-lg-lh-min: 48;
  --fz-body-lg-lh-to: 56;
  --fz-body-lg-lh-max: 65;
  --fz-body-md-case-study-min: 21;
  --fz-body-md-case-study-from: 21;
  --fz-body-md-case-study-max: 31;
  --fz-body-md-case-study-lh-min: 32;
  --fz-body-md-case-study-lh-from: 34;
  --fz-body-md-case-study-lh-max: 46;
  --fz-body-md-min: 21;
  --fz-body-md-from: 21;
  --fz-body-md-max: 31;
  --fz-body-md-lh-min: 32;
  --fz-body-md-lh-from: 32;
  --fz-body-md-lh-max: 46;
  --fz-body-sm-min: 18;
  --fz-body-sm-max: 18;
  --fz-body-sm-lh-min: 32;
  --fz-body-sm-lh-max: 32;
  --fz-body-xl-min: 28;
  --fz-body-xl-max: 28;
  --fz-body-xl-lh-min: 36;
  --fz-body-xl-lh-max: 36;
  --fz-body-xs-min: 16;
  --fz-body-xs-max: 16;
  --fz-body-xs-lh-min: 20;
  --fz-body-xs-lh-max: 20;
  --fz-cite-min: 24;
  --fz-cite-max: 24;
  --fz-cite-lh-min: 30;
  --fz-cite-lh-max: 30;
  --fz-cta-text-min: 16;
  --fz-cta-text-max: 16;
  --fz-cta-text-lh-min: 32;
  --fz-cta-text-lh-max: 32;
  --fz-eyebrow-min: 18;
  --fz-eyebrow-max: 18;
  --fz-eyebrow-lh-min: 30;
  --fz-eyebrow-lh-max: 30;
  --fz-h1-min: 60;
  --fz-h1-to: 200;
  --fz-h1-max: 234;
  --fz-h1-lh-min: 57;
  --fz-h1-lh-to: 177;
  --fz-h1-lh-max: 206;
  --fz-h2-min: 50;
  --fz-h2-to: 110;
  --fz-h2-max: 190;
  --fz-h2-lh-min: 57;
  --fz-h2-lh-to: 120;
  --fz-h2-lh-max: 200;
  --fz-h3-min: 40;
  --fz-h3-to: 60;
  --fz-h3-max: 83;
  --fz-h3-lh-min: 42;
  --fz-h3-lh-to: 58;
  --fz-h3-lh-max: 76;
  --fz-h4-alt-min: 34;
  --fz-h4-alt-to: 74;
  --fz-h4-alt-max: 120;
  --fz-h4-alt-lh-min: 38;
  --fz-h4-alt-lh-to: 87;
  --fz-h4-alt-lh-max: 143;
  --fz-h4-min: 34;
  --fz-h4-to: 36;
  --fz-h4-max: 44;
  --fz-h4-lh-min: 38;
  --fz-h4-lh-to: 38;
  --fz-h4-lh-max: 46;
  --fz-h5-min: 26;
  --fz-h5-to: 32;
  --fz-h5-lh-min: 32;
  --fz-h5-lh-to: 42;
  --fz-job-title-min: 27;
  --fz-job-title-max: 27;
  --fz-job-title-lh-min: 32;
  --fz-job-title-lh-max: 32;
  --fz-links-min: 20;
  --fz-links-max: 20;
  --fz-links-lh-min: 28;
  --fz-links-lh-max: 28;
  --fz-list-min: 22;
  --fz-list-max: 22;
  --fz-list-lh-min: 38;
  --fz-list-lh-max: 38;
  --fz-meta-blog-min: 20;
  --fz-meta-blog-max: 20;
  --fz-meta-blog-lh-min: 25;
  --fz-meta-blog-lh-max: 25;
  --fz-meta-photo-min: 12;
  --fz-meta-photo-to: 16;
  --fz-meta-photo-max: 20;
  --fz-meta-photo-lh-min: 20;
  --fz-meta-photo-lh-max: 20;
  --fz-name-min: 16;
  --fz-name-max: 16;
  --fz-name-lh-min: 20;
  --fz-name-lh-max: 20;
  --fz-nav-links-min: 46;
  --fz-nav-links-max: 20;
  --fz-nav-links-lh-min: 46;
  --fz-nav-links-lh-max: 20;
  --fz-quote-min: 36;
  --fz-quote-to: 56;
  --fz-quote-max: 79;
  --fz-quote-lh-min: 46;
  --fz-quote-lh-to: 70;
  --fz-quote-lh-max: 98;
  --fz-routing-links-min: 20;
  --fz-routing-links-max: 20;
  --fz-routing-links-lh-min: 28;
  --fz-routing-links-lh-max: 28;
  --fz-stats-k-min: 100;
  --fz-stats-k-max: 100;
  --fz-stats-k-lh-min: 100;
  --fz-stats-k-lh-max: 100;
  --fz-stats-min: 150;
  --fz-stats-max: 150;
  --fz-stats-lh-min: 100;
  --fz-stats-lh-max: 100;
  --fz-tag-md-min: 24;
  --fz-tag-md-max: 24;
  --fz-tag-md-lh-min: 24;
  --fz-tag-md-lh-max: 24;
  --fz-tag-sm-min: 16;
  --fz-tag-sm-max: 16;
  --fz-tag-sm-lh-min: 26;
  --fz-tag-sm-lh-max: 26;
  --fz-tags-min: 16;
  --fz-tags-max: 16;
  --fz-tags-lh-min: 32;
  --fz-tags-lh-max: 32;
  --ls-0: 0em;
  --ls-1: 0.01em;
  --ls-2: 0.02em;
  --ls-3: 0.03em;
  --ls-4: 0.04em;
  --ls-5: 0.05em;
  --ls-25: 0.025em;
  --ls-m1: -0.01em;
  --ls-m2: -0.02em;
  --ls-m3: -0.03em;
  --ls-m4: -0.04em;
  --ls-m5: -0.05em;
  --sp-page-padding-inline: 50px;
  --sp-page-padding-inline-mobile: 10px;
  --td-button-choices-default: btn;
  --td-button-choices-inline: inline-btn;
  --tx-time: 1;
  --tx-sec: calc(var(--tx-time) * 1s);
  --tx: var(--tx-sec) ease-in-out;
  --sp-xl-desktop: 300px;
  --sp-xl-mobile: 120px;
  --sp-xl-half-desktop: 150px;
  --sp-xl-half-mobile: 60px;
  --sp-lg-desktop: 180px;
  --sp-lg-mobile: 120px;
  --sp-md-desktop: 130px;
  --sp-md-mobile: 52px;
  --sp-sm-desktop: 60px;
  --sp-sm-mobile: 52px;
  --sp-xs-desktop: 30px;
  --sp-xs-mobile: 30px;
  --sp-none: 0px;
  --sp-xl: var(--sp-xl-desktop);
  --sp-xl-half: var(--sp-xl-half-desktop);
  --sp-lg: var(--sp-lg-desktop);
  --sp-md: var(--sp-md-desktop);
  --sp-sm: var(--sp-sm-desktop);
  --sp-xs: var(--sp-xs-desktop);
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--ink: var(--ink, #232323);
  --wp--preset--color--paper: var(--paper, #fafafa);
  --wp--preset--color--highlight-1: var(--highlight-1, #8875e2);
  --wp--preset--color--highlight-2: var(--highlight-2, #de706f);
  --wp--preset--color--highlight-3: var(--highlight-3, #5d9e70);
  --wp--preset--color--tag-color: var(--tag-color, #727272);

  --lh-max: var(--lh-has-max, var(--lh-min));
  --lh-min: var(--lh-has-min, var(--line-height));
  --lh-has-max: min(var(--lh-max-px), var(--line-height));
  --lh-has-min: max(var(--lh-min-px), var(--line-height));
  line-height: var(--lh-clamp, var(--lh-max));
  --viewport-from: var(--override-viewport-from, 420);
  --viewport-to: var(--override-viewport-to, 1512);
  --min-font-size: var(--fz-routing-links-min);
  --max-font-size: var(--fz-routing-links-max);
  --min-line-height: var(--fz-routing-links-lh-min);
  --max-line-height: var(--fz-routing-links-lh-max);
  font-family: Halenoir Display, sans-serif;
  letter-spacing: 0.02em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: start;
  gap: 80px;
}

@media only screen and (max-width: 480px) {
  .left {
    gap: 2rem;
  }
}

.footer-menu {
  margin-block-end: 4em;
}

.footer-menu-title {
  font-size: 24px;
  margin-block-end: 1em;
  text-transform: uppercase;
  font-family: "Questrial", sans-serif;
  color: #151515;
  text-align: left;
  // margin-left: 10px;
}

@media only screen and (max-width: 480px) {
  .footer-menu-title {
    font-size: 20px;
    margin-left: 0px;
  }
}

.menu-title-phone {
  font-size: 24px;
  margin-block-end: 1em;
  text-transform: uppercase;
  font-family: "Questrial", sans-serif;
  color: #151515;
  text-align: left;
  @media (max-width: 480px) {
    font-size: 20px;
  }
}

ul {
  padding-left: 0;
  padding-right: 1rem;
}

li {
  margin-left: -5px;
  text-align: left;
}

li a {
  text-decoration: none;
  color: #151515;
  line-height: 1.6;
  font-size: 18px;
}

.text-footer-links {
  font-family: "Questrial", sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #151515;
}

.wp-widget-group__inner-blocks {
  text-align: left;
}

hr {
  opacity: 1 !important;
}

.li-footer {
  list-style: none;
}

.tel {
  color: #000;
}
